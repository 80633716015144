html {
  font: normal 1rem/1.5;
}

.bottombar {
  background: #303030;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 100%;
  display: flex;
  font-size: 2rem;
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.bottombar-container {
  display: flex;
  justify-content: right;
  width: 50%;
}

.impressum-container {
  display: flex;
  text-align: left;
  font-size: small;
  width: 50%;
}

.impressum-link {
  color: #fff;
}

.bottombar-logo {
  cursor: pointer;
  text-decoration: none;
  display: flex;
}

.bottom-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.bottom-item {
  height: 90px;
  padding: 2rem;
}

.logo:hover {
  transform: scale(1.5);
  transition: all 0.6s ease;
}

.bottom-links {
  font-family: "Raleway";
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
