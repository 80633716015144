.home__hero-section {
  padding: 120px 0;
  padding-bottom: 70px;
}

.home__hero-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.top-line {
  color: #989898;
  font-family: "Josefin Sans";
  text-transform: uppercase;
  font-weight: bold;
  font-size: medium;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 20px;
}

.heading {
  font-family: "Libre Baskerville";
  font-size: large;
  letter-spacing: 2px;
  margin-bottom: 10px;
  line-height: 1.1;
}

.heading-big {
  color: #38bb93;
  font-family: "Libre Baskerville";
  font-size: xx-large;
  letter-spacing: 2px;
  margin-bottom: 24px;
  line-height: 1.1;
}

.home__hero-subtitle {
  font-family: "Raleway";
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 16px;
}

.home__hero-img-wrapper {
  justify-content: right;
  max-width: 400px;
}

.home__hero-img {
  max-width: 95%;
  padding-right: 0;
  margin: 0 0 0 10px;
}

img {
  border: 2px solid #fff;
  border-radius: 50%;
  max-width: 100%;
  vertical-align: middle;
}

p {
  white-space: pre-line;
  margin-inline-end: auto;
  margin-inline-start: 0px;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .home__hero-section {
    padding-bottom: 50px;
  }
  .home__hero-text-wrapper {
    padding-bottom: 20px;
    text-align: center;
  }
  p {
    margin-inline-start: auto;
  }
  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
  .home__hero-img-wrapper {
    margin: 0 auto;
  }
}
