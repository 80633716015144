.about__hero-section {
  padding-top: 50px;
}

.about__hero-col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
}

.about__top-line {
  font-family: "Josefin Sans";
  color: #38bb93;
  text-transform: uppercase;
  font-weight: bold;
  font-size: x-large;
  letter-spacing: 2px;
  line-height: 16px;
  margin-bottom: 16px;
}

.about__hero-subtitle {
  font-family: "Raleway";
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 16px;
}

.about__hero-subtitle-bold {
  font-family: "Raleway";
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 30px;
  font-size: 16px;
}

.mailLink:link {
  background-color: transparent;
  text-decoration: none;
}

.mailLink:hover {
  color: #38bb93;
}
